<template>
  <div class="mt-4">
    <v-sheet
      v-if="loading"
      :color="`grey`"
      class="pa-3"
    >
      <v-skeleton-loader
        class="mx-auto"
        type="text-field, table, data-table"
      >
      </v-skeleton-loader>
    </v-sheet>
    <v-card v-else>
      <snack-view
        :message="snackbar.message"
        :activate="snackbar.show"
        :bg-color="snackbar.color"
      ></snack-view>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            sm="12"
            md="8"
            class="border"
          >
            <v-btn
              v-if="userData.state"
              dense
              text
              outlined
              class="my-sm-1 mr-1 col-md-4"
              :disabled="!decoderSelected.length"
              @click="programReabo"
            >
              <v-icon left>
                {{ icons.mdiReload }}
              </v-icon>
              Réabonner
            </v-btn>
            <v-btn
              v-if="userData.state"
              dense
              text
              outlined
              class="my-sm-1 mr-1 col-md-4 mt-sm-1"
              :disabled="isUpgradable"
              @click="programUpgrade"
            >
              <v-icon left>
                {{ icons.mdiArrowTopRight }}
              </v-icon>
              Upgrade
            </v-btn>
            <v-btn
              v-if="showAddDecoderBtn"
              dense
              text
              class="my-sm-1 col-md-4 mt-sm-1"
              outlined
              :disabled="activeElement || !!decoderSelected.length"
              @click="AddDecodeur"
            >
              <v-icon left>
                {{ icons.mdiPlus }}
              </v-icon>
              Ajouter des décodeurs
            </v-btn>
            <v-btn
              class="my-sm-1 ml-sm-1 col-md-4"
              dense
              text
              outlined
              :disabled="!decoderSelected.length"
              @click="openDialogPrel"
            >
              <!--            <v-icon left>-->
              <!--              {{ icons.mdiPlus }}-->
              <!--            </v-icon>-->
              <span v-if="truth.length!==0 && fail.length===0">Désactiver tout (Prel Auto)</span>
              <span v-else-if="truth.length===0 && fail.length!==0">Activer tout (Prel Auto)</span>
              <span v-else>Activer tout (Prel Auto)</span>
            </v-btn>
            <v-btn
              dense
              text
              outlined
              class="my-sm-1 mr-1 col-md-4 mt-sm-1"
              disabled
            >
              Réactivation
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="search"
              :append-icon="icons.mdiMagnify"
              label="Rechercher"
              single-line
              hide-details
              dense
              outlined
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="selectOffre"
              outlined
              dense
              :items="formule"
              item-text="label"
              item-value="code"
              :menu-props="{ offsetY : true }"
              placeholder="Offre"
              label="Offre"
              hide-details="auto"
              class="mb-3"
            ></v-select>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="selectState"
              outlined
              dense
              :items="statusList"
              item-text="label"
              item-value="name"
              :menu-props="{ offsetY : true }"
              placeholder="Status"
              label="Status"
              hide-details="auto"
              class="mb-3"
            ></v-select>
          </v-col>
        </v-row>
      <!--      <v-row>-->
      <!--        <v-col-->
      <!--          cols="12"-->
      <!--          offset-md="3"-->
      <!--          md="3"-->
      <!--        >-->
      <!--          <v-select-->
      <!--            v-model="selectOffre"-->
      <!--            outlined-->
      <!--            dense-->
      <!--            :items="formule"-->
      <!--            item-value="code"-->
      <!--            item-text="label"-->
      <!--            :menu-props="{ offsetY : true }"-->
      <!--            label="Formule"-->
      <!--            hide-details="auto"-->
      <!--            return-object-->
      <!--            @change="changOffreFilter(selectOffre)"-->
      <!--          ></v-select>-->
      <!--        </v-col>-->
      <!--        <v-col-->
      <!--          cols="12"-->
      <!--          md="3"-->
      <!--        >-->
      <!--          <v-select-->
      <!--            v-model="selectState"-->
      <!--            outlined-->
      <!--            dense-->
      <!--            :items="etat"-->
      <!--            item-value="name"-->
      <!--            item-text="label"-->
      <!--            :menu-props="{ offsetY : true }"-->
      <!--            label="Etat"-->
      <!--            hide-details="auto"-->
      <!--            @change="changeStateFilter(selectState)"-->
      <!--          ></v-select>-->
      <!--        </v-col>-->
      <!--      </v-row>-->
      </v-card-text>
      <v-data-table
        v-model="decoderSelected"
        :headers="headers"
        :items="decodeurListFormatted"
        :divider="true"
        :items-per-page="15"
        :search="search"
        item-key="id"
        show-select
      >
        <!--      <template v-slot:item = "{ item }">-->
        <!--        <decodeur-table :data="item" :key="item.number" :options="option" :formules="formule"></decodeur-table>-->
        <!--      </template>-->

        <template #[`item.is_auto`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              <v-switch
                :key="item.number"
                v-model="item.reabo_auto"
                color="success"
                :class="{'swicth-custom': item.reabo_auto===false}"
                inset
                :readonly="updatePrel"
                @mousedown="openDialogPrel(item)"
              >
                <template v-slot:default>
                </template>
                <template v-slot:label>
                  <span :class="item.reabo_auto===true?'success--text':'primary--text'">
                    {{ item.reabo_auto === true ? 'Activé' : 'Désactivé' }}
                  </span>
                </template>
              </v-switch>
            </div>
          </div>
        </template>

        <template #[`item.formula`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column justify-content-center text-center">
              <span class="d-block font-weight-semibold text--primary text-truncate"> {{
                nomFormule(item.formula)
              }}</span>
              <small> <span class="font-weight-semibold text--primary">Option :</span>
                {{ nomOption(item.option) }}</small>
            </div>
          </div>
        </template>

        <template #[`item.start_subscription`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column">
              <span class="d-block font-weight-semibold success--text text-truncate">{{
                formatDate(item.start_subscription)
              }}</span>
            </div>
          </div>
        </template>

        <template #[`item.end_subscription`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              <span class="d-block font-weight-semibold error--text text-truncate">{{
                formatDate(item.end_subscription)
              }}</span>
            </div>
          </div>
        </template>

        <template #[`item.remaining`]="{item}">
          <div>
            <v-progress-circular
              :value="item.remaining"
              :color="(item.state===1 && item.days>5) ?'success':'error'"
              :size="60"
              :rotate="-90"
            >
              <span class="text-sm"><small>{{ item.state == 1 ? item.days : item.days }} jours</small></span>
            </v-progress-circular>
          </div>
        </template>

        <!-- status -->
        <template #[`item.state`]="{item}">
          <v-chip
            small
            :color="statusColor[status[item.state]]"
            :class="`${statusColor[status[item.state]]}--text`"
            class="v-chip-light-bg"
          >
            <small v-if="item.state===1"> {{ status[item.state] }}</small>
            <small v-else> {{ status[item.state] }} </small>
          </v-chip>
        </template>
        <template #[`item.actions`]="{ item }">
          <div class="col-12 text-center">
            <v-btn
              v-if="isVisu()"
              class="ml-1"
              outlined
              x-small
              color="error"
              @click="deleteItem(item)"
            >
              <v-icon
                small
              >
                {{ icons.mdiDeleteOutline }}
              </v-icon>
              Supprimer
            </v-btn>
          </div>
        </template>
      </v-data-table>
      <!-- dialog édition -->
      <v-dialog
        v-model="dialog"
        max-width="800px"
      >
        <v-card>
          <v-card-title>
            <span>Ajout Décodeur</span>
          </v-card-title>
          <v-divider></v-divider>
          <!-- Add With Excell File -->
          <v-container v-if="!isApiCanalAvailable">
            <div class="col-md-12">
              <label for="file">Ajouter via le fichier Excel</label>
              <v-file-input
                v-model="xlFile"
                :accept="filesAccepted"
                label="Reçu"
                outlined
                dense
                :rules="LocalNameRules"
                class="p-0"
                @change="loadXlFile"
              >
                <v-icon slot="prependIcon">
                  {{ icons.mdiPaperclip }}
                </v-icon>
              </v-file-input>
              <!--            <input-->
              <!--              class="form-control d-inline-block"-->
              <!--              type="file"-->
              <!--              @change=""-->
              <!--            >-->
              <xlsx-read :file="xlFile">
                <!--          <xlsx-sheets>-->
                <!--            <template #default="{sheets}">-->
                <!--              <div>-->
                <!--                {{ sheets }}-->
                <!--                {{ getLeafs(sheets) }}-->
                <!--              </div>-->
                <!--            </template>-->
                <!--          </xlsx-sheets>-->
                <xlsx-json
                  sheet="Feuil1"
                  @parsed="changed"
                >
                </xlsx-json>
              </xlsx-read>
            </div>
          </v-container>
          <v-divider></v-divider>
          <v-card-text>
            <v-form
              ref="decodeurForm"
            >
              <v-container>
                <!-- bloc pour le nouveau décodeur-->
                <fieldset
                  v-for="(decodeur, decodeurIndex) in Decodeur"
                  :key="decodeurIndex"
                >
                  <legend class="ml-2 pl-2 pr-2">
                    Création du décodeur <b class="blue">{{ decodeur.number }}</b>
                  </legend>

                  <v-card-text class="text-right mr-4">
                    <v-btn
                      v-if="decodeurIndex !=0"
                      text
                      color="error"
                      small
                      @click="Decodeur.splice(decodeurIndex, 1)"
                    >
                      <v-icon color="danger">
                        {{ icons.mdiCloseCircle }}
                      </v-icon>
                      Supprimer
                    </v-btn>
                  </v-card-text>
                  <v-row class="ml-6 mr-6">
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="decodeur.number"
                        label="Numéro du décodeur"
                        :rules="LocalNumberRules"
                        type="number"
                        outlined
                        dense
                        placeholder="Numéro du décodeur"
                        hide-details="auto"
                      ></v-text-field>
                    </v-col>

                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="decodeur.location"
                        label="Emplacement"
                        outlined
                        dense
                        :rules="LocalNameRules"
                        placeholder="Emplacement"
                        hide-details="auto"
                      ></v-text-field>
                    </v-col>

                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-select
                        v-model="decodeur.formula"
                        outlined
                        dense
                        :items="formule"
                        item-text="label"
                        item-value="code"
                        :menu-props="{ offsetY : true }"
                        placeholder="Sélectionnez la formule"
                        label="Formule du décodeur"
                        :rules="LocalNameRules"
                        hide-details="auto"
                        class="mb-3"
                      ></v-select>
                    </v-col>

                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-select
                        v-model="decodeur.option"
                        outlined
                        dense
                        :items="option"
                        item-text="nom"
                        item-value="code"
                        :menu-props="{ offsetY : true }"
                        placeholder="Sélectionnez l'option"
                        label="Option du décodeur"
                        hide-details="auto"
                        class="mb-3"
                      ></v-select>
                    </v-col>

                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-menu
                        v-model="decodeur.menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="decodeur.end_subscription"
                            label="Date de fin d'abonnement"
                            :readonly="true"
                            :value="formatDate(decodeur.end_subscription)"
                            v-bind="attrs"
                            outlined
                            dense
                            v-on="on"
                          >
                            <template v-slot:append>
                              <v-icon>{{ icons.mdiCalendarMonth }}</v-icon>
                            </template>
                          </v-text-field>
                        </template>
                        <v-date-picker
                          v-model="decodeur.end_subscription"
                          color="primary"
                          locale="pr-fr"
                          @input="decodeur.menu2 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col
                      cols="12"
                      md="12"
                      class="d-sm-flex flex-sm-row-reverse pr-0 mt-n8 pb-10"
                    >
                      <div
                        class="d-sm-flex flex-sm-row-reverse align-content-end"
                      >
                        <v-switch
                          :key="decodeurIndex"
                          v-model="decodeur.reabo_auto"
                          color="success"
                          inset
                          :readonly="updatePrel"
                          :class="[(decodeur.reabo_auto===false)?'swicth-custom mt-n1':'', 'mt-n1']"
                          @mousedown="openDialogPrel(decodeur,decodeurIndex)"
                        >
                        </v-switch>
                        <h3 class="mt-1 mr-2">
                          <b>Renouvellement automatique ?</b>
                        </h3>
                      </div>
                    </v-col>
                  </v-row>
                </fieldset>
                <!-- bouton d'ajout du décodeur-->
                <div
                  v-if="isApiCanalAvailable"
                  class="col-md-12"
                >
                  <v-card style="border: 1px solid #ccc6c6">
                    <v-card-text>
                      <v-card-text class="primary--text">
                        Recherche de décodeur
                      </v-card-text>
                      <v-divider></v-divider>

                      <v-row class="ml-6 mr-6 mt-2">
                        <div>
                          <snack-view
                            :message="snackbar.message"
                            :activate="snackbar.active"
                          ></snack-view>
                          <div
                            v-if="submited"
                            class="position-flex-center"
                          >
                            <v-progress-circular
                              :size="100"
                              :width="7"
                              color="primary"
                              indeterminate
                            ></v-progress-circular>
                          </div>
                          <div
                            v-else
                            class="py-6 mb-4"
                          >
                            <!--                                  <legend class="ml-4 pl-2 pr-2">-->
                            <!--                                    Recherche de décodeur-->
                            <!--                                  </legend>-->
                            <v-form
                              ref="searchForm"
                            >
                              <v-row class="mx-auto">
                                <v-col
                                  cols="12"
                                  md="5"
                                >
                                  <v-select
                                    v-model="recherche.type"
                                    outlined
                                    dense
                                    :rules="LocalNameRules"
                                    :items="typeRecherche"
                                    item-value="id"
                                    item-text="name"
                                    :menu-props="{ offsetY : true }"
                                    placeholder="Type de recherche"
                                    label="Type de recherche"
                                    hide-details="auto"
                                    return-object
                                    @input="changePrefix"
                                  ></v-select>
                                </v-col>
                                <v-col
                                  cols="12"
                                  md="4"
                                >
                                  <v-text-field
                                    v-model="recherche.value"
                                    label="valeur de la recherche"
                                    :rules="LocalNameRules"
                                    outlined
                                    dense
                                    placeholder="valeur de la recherche"
                                    hide-details="auto"
                                    :prefix="prefix"
                                  ></v-text-field>
                                </v-col>
                                <v-col
                                  cols="12"
                                  md="3"
                                >
                                  <v-btn
                                    color="primary"
                                    @click="searchDecodeur"
                                  >
                                    <v-icon>fas fa-search</v-icon> Rechercher
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </v-form>
                          </div>
                          <v-dialog
                            v-model="dialogApi"
                            persistent
                          >
                            <v-card>
                              <v-card-title>
                                <span>  Résultat de la recherche</span>
                              </v-card-title>
                              <v-divider></v-divider>
                              <v-data-table
                                v-model="decoderApiSelected"
                                :headers="headersDetails"
                                :items="decodeurs"
                                :items-per-page="-1"
                                sort-by="id"
                                show-select
                                :disable-pagination="true"
                                :hide-default-footer="true"
                                @toggle-select-all="selectAllToggle"
                              >
                                <template v-slot:item.data-table-select="{ item, isSelected, select }">
                                  <v-simple-checkbox
                                    :value="isSelected"
                                    :readonly="item.disabled"
                                    :disabled="item.disabled"
                                    @input="select($event)"
                                  ></v-simple-checkbox>
                                </template>

                                <template #[`item.actions`]="{ item }">
                                  <div class="col-12">
                                    <v-btn
                                      outlined
                                      color="success"
                                      class="mt-1"
                                      :disabled="item.state===-1"
                                    >
                                      <v-icon
                                        small
                                        class="mr-2"
                                      >
                                        fas fa-plus
                                      </v-icon>
                                      Ajouter
                                    </v-btn>
                                  </div>
                                </template>
                              </v-data-table>
                              <v-divider></v-divider>
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  class="mt-4"
                                  color="error"
                                  outlined
                                  @click="closeApi"
                                >
                                  Annuler
                                </v-btn>
                                <v-btn
                                  class="mt-4"
                                  color="primary"
                                  @click="validate"
                                >
                                  Valider
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </div>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </div>

                <v-card-text class="mt-4 text-right">
                  <v-btn
                    color="primary"
                    x-small
                    text
                    @click="addNewDecodeur"
                  >
                    Ajouter un Décodeur
                    <v-icon>{{ icons.mdiPlusCircle }}</v-icon>
                  </v-btn>
                </v-card-text>
              </v-container>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              outlined
              @click="close"
            >
              Annuler
            </v-btn>
            <v-btn
              v-if="isVisu()"
              color="primary"
              @click="createDecodeur"
            >
              Enregistrer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="dialogPrel"
        max-width="800px"
        persistent
      >
        <v-card>
          <v-card-title>
            <h4 class="text-decoration-underline text-center">
              CONDITIONS GENERALES DU SERVICE DE
              PRÉLÈVEMENT AUTOMATIQUE
            </h4>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="text-justify primary--text">
            <p class="text-body-1">
              Le prélèvement automatique est la solution idéale pour vous permettre d’éviter la perte
              de vos images, de renouveler vos abonnements sans tracasseries, et surtout bénéficier de
              la semaine généreuse ou tous autres avantages liés au payement avant échéance.
            </p>
            <p class="text-body-1">
              L’acceptation du présent contrat emporte souscription au paiement automatique des frais
              d’abonnement aux offres CANAL+, ainsi que l’acceptation des conditions générales du
              service ci-après.
            </p>
            <p class="text-body-1">
              Le montant des abonnements indiqués sera automatiquement prélevé de votre compte selon
              le moyen de paiement sélectionné et modifiable mensuellement.
            </p>
            <p class="text-body-1">
              Le montant à prélever correspond à celui des abonnements en cours, sans aucun frais
              supplémentaire.
            </p>
            <p class="text-body-1">
              Les prélèvements pourront être faits à partir du cinquième jour avant la fin de(s)
              abonnement(s) en cours.
            </p>
            <p class="text-body-1">
              En cas d’absence de fonds sur votre compte pendant toute la période indiquée ci-dessus,
              le défaut de paiement sera acté et celui-ci sera suspendu à la date de fin d’abonnement.
              Il sera néanmoins possible d’effectuer un prélèvement durant les cinq jours suivant
              l’échéance, si les fonds disponibles sur le compte le permettent.
              Le souscripteur sera instantanément notifié de tout prélèvement.
            </p>
            <p class="text-body-1">
              Bien vouloir cliquer sur <span class="success--text font-weight-bold text-lg-h6">« J’accepte »</span> pour
              le valider sinon sur <span class="error--text font-weight-bold text-lg-h6">« Je renonce »</span>.
            </p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="d-flex justify-center">
            <v-btn
              color="error"
              outlined
              @click="refusePrel"
            >
              Je renonce
            </v-btn>
            <v-btn
              v-if="isVisu()"
              color="primary"
              @click="acceptPrel"
            >
              J’accepte
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- dialog suppression -->
      <v-dialog
        v-model="dialogDelete"
        max-width="550px"
      >
        <v-card>
          <v-card-title>
            Êtes-vous sûr de vouloir supprimer ce décodeur ?
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              outlined
              @click="closeDelete"
            >
              Annuler
            </v-btn>
            <v-btn
              color="success"
              @click="deleteItemConfirm"
            >
              Confirmer
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiReload,
  mdiArrowTopRight,
  mdiPlus,
  mdiCloseCircle,
  mdiPlusCircle,
  mdiCalendarMonth,
  mdiPaperclip,
  mdiDeleteOutline,
} from '@mdi/js'
import Http from '@/helpers/http'
import moment from 'moment/moment'
import {
  ref, computed, onBeforeMount, onMounted, watch, nextTick,
} from '@vue/composition-api'
// eslint-disable-next-line import/extensions
import SnackView from '@/views/SnackView'
// eslint-disable-next-line import/no-extraneous-dependencies
import XlsxJson from 'vue-xlsx/dist/components/XlsxJson'
// eslint-disable-next-line import/no-extraneous-dependencies
import XlsxRead from 'vue-xlsx/dist/components/XlsxRead'
// eslint-disable-next-line import/no-extraneous-dependencies
import Vue from 'vue'
import { useRouter } from '@core/utils'
import { REABO, UPGRADE } from '@/helpers/constants/operation'
import Session from '@/helpers/session'
import store from '@/store'
import useDynamicConstant from '@/helpers/useDynamicConstant'
import { isVisu } from '@/helpers/constants/roles'

Vue.prototype.moment = moment
export default {
  components: {
    SnackView,
    XlsxJson,
    XlsxRead,
  },
  props: {
    data: {
      type: Object,
      required: false,
    },
    id: {
      type: [Number, String],
      required: true,
    },
    state: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const chartOptions = {
      chart: {
        sparkline: {
          enabled: true,
        },
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: '40%',
          },
          track: {
            background: '#ebe9f1',
          },
          dataLabels: {
            show: false,
            name: {
              show: false,
            },
          },
        },
      },

      grid: {},
    }

    const userData = JSON.parse(Session.get('userData'))

    const { router } = useRouter()
    const { route } = useRouter()

    const loading = ref(true)

    const xlFile = ref(null)

    const { apiCanalAvailability } = useDynamicConstant()

    const isApiCanalAvailable = ref(0)

    apiCanalAvailability(resp => {
      isApiCanalAvailable.value = resp
    })

    const loadXlFile = e => {
      // xlFile.value = e.target.files ? e.target.files[0] : null
    }

    const snackbar = ref({
      message: '',
      active: false,
    })

    // Type de fichier accepté par le champ Reçu
    const filesAccepted = [
      '.xlsx', '.xls',
    ]

    const etat = ref([
      { name: 'echu', label: 'Echu' },
      { name: 'echeance', label: 'Echeance' },
      { name: 'actif', label: 'Actif' },
    ])

    const decoderSelected = ref([])

    const formule = ref([])
    const option = ref([{
      alpha2: 'CM',
      code: 'AUCUNE',
      id: 0,
      nom: 'Aucune',
      pu: 0,
    }])

    const statusList = ref([
      { label: 'Tout', name: '' },
      { label: 'Actif', name: 'actif' },
      { label: 'Échéance', name: 'echeance' },
      { label: 'Échu', name: 'echu' },
    ])

    const selectOffre = ref('')
    const selectState = ref('')

    const formatDate = dateString => {
      if (dateString !== null) {
        return moment(dateString, 'YYYY-MM-DD').format('DD/MM/YYYY')
      }

      return 'N/A'
    }

    const search = ref('')
    const activeElement = ref(false)

    const isAuto = item => item.is_auto

    const LocalNameRules = [
      v => !!v || 'Ce champ est réquis',
    ]

    const LocalNumberRules = [
      v => !!v || 'Ce champ est réquis',
      v => /[0-9]{12,14}/.test(v) || 'Ce numéro doit contenir au moins 12 chiffres',
      // eslint-disable-next-line no-restricted-globals,no-mixed-operators
      v => (!isNaN(parseFloat(v))) && v > 0 || 'Veuillez entrer un nombre positif',
    ]

    const clearDateFin = el => {
      // eslint-disable-next-line no-param-reassign
      if ((new Date(el.end_subscription).getTime()) < (new Date(el.start_subscription).getTime())) el.end_subscription = el.start_subscription
    }

    const local = ref({})

    const decodeurList = ref([])

    const Decodeur = ref([
      // {
      // number: null,
      // location: '',
      // formula: '',
      // option: '',
      // end_subscription: '',
      // menu: '',
      // menu2: '',
      // is_auto: 0,
      // reabo_auto: false,
    // }
    ])

    const statusColor = {
      /* eslint-disable key-spacing */
      Actif: 'success',
      Echu: 'error',
      /* eslint-enable key-spacing */
    }

    const currentDate = moment().format('YYYY-MM-DD')
    const remainingDay = decodeur => {
      const startSubscription = (decodeur.start_subscription === null) ? moment(decodeur.created_at).format('YYYY-MM-DD') : decodeur.start_subscription
      const start = moment(startSubscription, 'YYYY-MM-DD')
      const end = moment(decodeur.end_subscription, 'YYYY-MM-DD')
      const days = moment(start).diff(end, 'days')
      const remainingDays = moment(end).diff(currentDate, 'days')
      if (remainingDays <= 0) {
        const mday = Math.abs(remainingDays)

        // const pourcentage = Math.round(Math.abs(((remainingDays * 100) / days)))

        return { remaining: 100, day: mday, echu: true }
      }
      const pourcentage = Math.round(Math.abs(((remainingDays * 100) / days)))
      const rday = Math.abs(remainingDays)

      return { remaining: pourcentage, day: rday, echu: false }
    }

    const loadFormules = () => {
      Http.get('get-formules')
        .then(response => {
          formule.value = response.data
          formule.value.unshift({ label: 'Toute', code: '' })
        })
        .catch(error => {
          console.error('Erreur :', error)
        })
    }

    const localItem = ref({})

    const loadOptions = () => {
      Http.get('get-options')
        .then(response => {
          option.value = [...option.value, ...response.data]
        })
        .catch(error => {
          console.error('Erreur :', error)
        })
    }

    const decodeurListFormatted = computed(() => {
      decodeurList.value.forEach(item => {
        // eslint-disable-next-line no-param-reassign
        const valeur = remainingDay(item)
        // eslint-disable-next-line no-param-reassign
        item.remaining = valeur.remaining
        // eslint-disable-next-line no-param-reassign
        item.days = valeur.day
        // eslint-disable-next-line no-param-reassign
        item.echu = valeur.echu
        // eslint-disable-next-line no-param-reassign
        item.reabo_auto = item.is_auto === 1
        // eslint-disable-next-line no-param-reassign
        item.state = item.echu === false ? 1 : 2
      })

      return decodeurList.value
    })

    const nomFormule = code => {
      // eslint-disable-next-line array-callback-return
      const name = formule.value.filter(item => item.code === code)
      if (name.length === 1) return name[0].label

      return code
    }

    const contrat = JSON.parse(localStorage.getItem('defaultContrat'))
    const role = JSON.parse(localStorage.getItem('defaultRole'))
    const staticlocalId = ref('')
    const qState = ref('')

    const ACTIF = 1
    const ECHU = 2

    const filterDecodeurByState = state => {
      console.log('state')
      console.log(state)
      console.log('decodeurs')
      console.log(decodeurList.value)
      const dFormated = decodeurListFormatted.value
      // eslint-disable-next-line default-case
      switch (state) {
        case 'actif':
          decodeurList.value = dFormated.filter(item => (item.state === ACTIF && item.days > 5))
          break
        case 'echu':
          decodeurList.value = dFormated.filter(item => item.state === ECHU)
          break
        case 'echeance':
          decodeurList.value = dFormated.filter(item => (item.days >= 1 && item.days <= 5 && item.state === ACTIF))
          break
      }
    }

    const filterDecodeurByFormule = formuleCheck => {
      const dFormated = decodeurListFormatted.value
      decodeurList.value = dFormated.filter(i => i.formula === formuleCheck)
    }

    const loadDecodeurs = localId => {
      Http.post('get-local-by-id', { id: localId, contractId: contrat.id, roleId: role.id })
        // eslint-disable-next-line no-return-assign
        .then(response => {
          local.value = response.data
          decodeurList.value = local.value[0].decoders
          staticlocalId.value = local.value[0].id

          if (selectState.value !== '') filterDecodeurByState(selectState.value)
          if (selectOffre.value !== '') filterDecodeurByFormule(selectOffre.value)
        })
        .catch(error => error)
    }

    const qRoute = ref(route.value.query)

    const loadAllDecodeurs = () => {
      // const parameters = { id: user.id, roleId: role.id, contractId: contrat.id }
      // console.log(parameters)

      Http.get(`get-decoders/${JSON.parse(localStorage.getItem('defaultContrat')).id}`)
        // eslint-disable-next-line no-return-assign
        .then(response => {
          decodeurList.value = response.data
          console.log('chargement de tous les decodeurs terminés')
          console.log(decodeurList.value)
          const state = qRoute.value.qState
          // eslint-disable-next-line prefer-destructuring
          console.log('state')
          console.log(state)
          if (state !== undefined) {
            const stateValue = statusList.value.filter(i => i.name === state)[0]
            selectState.value = stateValue.name
            filterDecodeurByState(state)
          } else if (selectState.value !== '') {
            filterDecodeurByState(selectState.value)
          }
          if (selectOffre.value !== '') filterDecodeurByFormule(selectOffre.value)
        })
        .catch(error => {
          console.log(error)
        })
    }

    const showAddDecoderBtn = ref(false)

    onBeforeMount(() => {
      // eslint-disable-next-line no-restricted-globals
      if (!isNaN(props.id)) {
        showAddDecoderBtn.value = true
        loadDecodeurs(props.id)

        // Http.post('get-local-by-id', { id: props.id, contractId: contrat.id, roleId: role.id })
        //   // eslint-disable-next-line no-return-assign
        //   .then(response => {
        //     local.value = response.data
        //     decodeurList.value = local.value[0].decoders
        //     staticlocalId.value = local.value[0].id
        //     console.log('chargement des decodeurs terminés')
        //   })
        //   .catch(error => error)
      } else if (props.id === 'all') {
        showAddDecoderBtn.value = false
        loadAllDecodeurs()
      }
      loadFormules()
      loadOptions()
    })

    onMounted(() => {
      Http.post('getFileUrl', { path: 'AA030/recharge-compte-financier/ACCOUNTING-REFILL-REQUEST-43.png' })
        .then(resp => {
          console.log(resp)
        })
        .catch(error => {
          console.log(error)
        })

      setTimeout(() => {
        loading.value = false
      }, 5000)
    })

    const imunition = ref([])

    const codeFormule = labelFormule => {
      const code = formule.value.filter(i => (i.label).toUpperCase() === (labelFormule).toUpperCase())

      return (code.length) ? code[0].code : ''
    }

    const codeOption = labelOption => {
      const code = option.value.filter(i => (i.nom).toUpperCase() === (labelOption).toUpperCase())

      return (code.length) ? code[0].code : ''
    }

    const changed = params => {
      imunition.value = params
      console.log('imunition')
      console.log(imunition.value)

      if (imunition.value !== null) {
        const tab = []
        imunition.value.forEach(i => {
          console.log('props.data.locals.length')
          if (i.NUMERO_DECODEUR && i.EMPLACEMENT) {
            setTimeout(() => {
              console.log(i)

              if (Decodeur.value[0].number === null) {
                // eslint-disable-next-line no-param-reassign
                console.log(i.OPTION)
                console.log(i.DATE_FIN_ABONNEMENT)
                console.log(moment(i.DATE_FIN_ABONNEMENT, 'DD/MM/YYYY').format('YYYY-MM-DD'))

                Decodeur.value[0].number = i.NUMERO_DECODEUR
                Decodeur.value[0].location = i.EMPLACEMENT
                Decodeur.value[0].formula = codeFormule(i.FORMULE)
                Decodeur.value[0].option = codeOption(i.OPTION)
                Decodeur.value[0].end_subscription = moment(i.DATE_FIN_ABONNEMENT, 'DD-MM-YYYY').format('YYYY-MM-DD')
                Decodeur.value[0].is_auto = 0
              } else {
                Decodeur.value.push(
                  {
                    number: i.NUMERO_DECODEUR,
                    location: i.EMPLACEMENT,
                    formula: codeFormule(i.FORMULE),
                    option: codeOption(i.OPTION),
                    end_subscription: moment(i.DATE_FIN_ABONNEMENT, 'DD-MM-YYYY').format('YYYY-MM-DD'),
                    is_auto: 0,
                  },
                )
              }

              // console.log(props.data.locals[indexLocal].Decodeur)
            }, 3000)
          }
        })
      }
    }

    const decodeurForm = ref(null)

    const reaboAutomatique = (item, valueAuto = null) => {
      snackbar.value.active = false
      const autoValue = (valueAuto == null) ? (item.reabo_auto ? 0 : 1) : valueAuto
      // eslint-disable-next-line no-param-reassign
      // item.is_auto = !item.is_auto
      // eslint-disable-next-line no-unreachable
      const params = { decoderId: item.id, isAutoValue: autoValue }

      Http.post('isAuto', params)
        .then(response => {
          // eslint-disable-next-line no-param-reassign
          console.log('isAuto')
          console.log(response)
          snackbar.value.message = 'Activation effectuée avec succès'
          snackbar.value.active = true
          if (refresh.value) {
            if (!isNaN(props.id)) {
              loadDecodeurs(props.id)
            } else if (props.id === 'all') {
              loadAllDecodeurs()
            }
          }
          refresh.value = true
        })
        .catch(error => {
          // eslint-disable-next-line no-unused-expressions
          console.log(error.data)
        })
    }

    const nomOption = code => {
      // eslint-disable-next-line array-callback-return,no-return-assign
      let name = option.value.filter(item => name = item.code === code)
      if (name.length === 1) return name[0].nom

      return code
    }

    const dialog = ref(false)

    const close = () => {
      decodeurForm.value.resetValidation()
      dialog.value = false
      // eslint-disable-next-line no-use-before-define
      // nextTick(() => {
      Decodeur.value = [
        // {
        // number: '',
        // location: '',
        // formula: '',
        // option: '',
        // start_subscription: currentDate,
        // end_subscription: '',
        // menu: '',
        // menu2: '',
        // is_auto: 0,
        // reabo_auto: false,
      // }
      ]

      // eslint-disable-next-line no-use-before-define
      recherche = { type: '', value: '' }

      // })
    }

    const isUpgradable = computed(() => {
      let i = 0
      if (!decoderSelected.value.length) return true
      // eslint-disable-next-line no-plusplus
      for (i = 0; i < decoderSelected.value.length; i++) {
        if (decoderSelected.value[i].echu !== false) {
          return true
        }
      }

      return false
    })

    const formatDecoderToSave = () => {
      let i = 0
      const tab = []
      console.log('Decodeur à formater')
      console.log(Decodeur.value)
      // eslint-disable-next-line no-plusplus
      for (i = 0; i < Decodeur.value.length; i++) {
        tab.push({
          numero: Decodeur.value[i].number,
          emplacement: Decodeur.value[i].location,
          formule: Decodeur.value[i].formula,
          endDate: Decodeur.value[i].end_subscription,
          option: Decodeur.value[i].option,
          is_auto: Decodeur.value[i].is_auto,
        })
      }

      return tab
    }

    const persistDecoders = () => {
      snackbar.value.active = false
      const decoderListCreation = {
        contractId: JSON.parse(localStorage.getItem('defaultContrat')).id,
        locals: [
          {
            local: staticlocalId.value,
            decoder: formatDecoderToSave(),
          },
        ],
      }
      Http.post('decoder', decoderListCreation)
        .then(rep => {
          if (rep.status === 201) {
            console.log('decodeur ajouté')
            console.log(rep.message)
            snackbar.value.message = rep.message
            snackbar.value.active = true
            console.log(rep.data)
            loadDecodeurs(staticlocalId.value)
          }
        }).catch(error => {
          console.log(error)
        })
    }

    const createDecodeur = () => {
      console.log('decoders')
      console.log(Decodeur.value)
      console.log('decoders')

      if (decodeurForm.value.validate()) {
        // snackbar.value.value = false
        // console.log(Decodeur)
        // Decodeur.value.forEach(item => {
        //   console.log('tour')
        //   console.log(item)
        //   decodeurList.value.push(item)
        // })
        // console.log('1')
        // console.log(local.value)
        persistDecoders()
        close()
      }
    }

    const programReabo = () => {
      console.log(decoderSelected.value)
      router.push({
        name: 'save-operation',
        params: { operationCheck: REABO, decoders: decoderSelected.value },
      })
    }

    const programUpgrade = () => {
      router.push({
        name: 'save-operation',
        params: { operationCheck: UPGRADE, decoders: decoderSelected.value },
      })
    }

    // const changOffreFilter = () => {
    //   console.log('bonjour')
    // }
    //
    // const changStateFilter = () => {
    //   route.query.qState = selectState.value
    // }

    const dialogPrel = ref(false)
    const prelItem = ref({})
    const updatePrel = ref(true)
    const isNewDecodeur = ref(false)
    const refresh = ref(true)
    const reaboAll = ref(false)

    const openDialogPrel = (item = null, idx = null) => {
      console.log('item,idx')
      console.log(item)
      console.log(idx)

      if (decoderSelected.value.length !== 0) {
        dialogPrel.value = true
        reaboAll.value = true
      } else {
        if (idx === null && item !== null) {
          prelItem.value = item
        } else {
          prelItem.value = Decodeur.value[idx]
          isNewDecodeur.value = true
        }

        if (item.is_auto === 0) {
          dialogPrel.value = true
        } else if (isNewDecodeur.value) {
          Vue.set(prelItem.value, 'reabo_auto', !prelItem.value.reabo_auto)
          Vue.set(prelItem.value, 'is_auto', prelItem.value.reabo_auto ? 1 : 0)
        } else {
          reaboAutomatique(item)
        }
      }
    }

    const closeDialogPrel = () => {
      nextTick(() => {
        prelItem.value = {}
        updatePrel.value = true
        dialogPrel.value = false
      })
    }

    const truth = computed(() => decoderSelected.value.filter(i => i.is_auto === 1))
    const fail = computed(() => decoderSelected.value.filter(i => i.is_auto === 0))

    const reaboAutoAll = () => {
      snackbar.value.active = false

      let i = 0
      let setToValue = 0

      if (fail.value.length !== 0) setToValue = 1

      for (i = 0; i < decoderSelected.value.length; i++) {
        refresh.value = false
        reaboAutomatique(decoderSelected.value[i], setToValue)

        if (i === decoderSelected.value.length - 1) {
          setTimeout(() => {
            if (!isNaN(props.id)) {
              loadDecodeurs(props.id)
            } else if (props.id === 'all') {
              loadAllDecodeurs()
            }
          }, 2000)
        }
      }
      reaboAll.value = false
    }

    const acceptPrel = () => {
      updatePrel.value = false
      if (reaboAll.value) {
        reaboAutoAll()
      } else if (isNewDecodeur.value) {
        Vue.set(prelItem.value, 'reabo_auto', !prelItem.value.reabo_auto)
        Vue.set(prelItem.value, 'is_auto', prelItem.value.reabo_auto ? 1 : 0)
      } else {
        reaboAutomatique(prelItem.value)
        Vue.set(decodeurListFormatted.value.filter(i => i.id === prelItem.value.id)[0], 'reabo_auto', prelItem.value.reabo_auto)
      }
      dialogPrel.value = false
      closeDialogPrel()
    }

    const refusePrel = () => {
      prelItem.value = {}
      closeDialogPrel()
    }

    /// /
    /// Partie recherche des abonnées via l'API
    /// //

    const dialogApi = ref(false)

    const searchForm = ref(null)

    const decoderApiSelected = ref([])

    const decodeurs = []

    let recherche = { type: '', value: '' }

    const prefix = ''

    const submited = false

    const typeRecherche = [
      {
        id: 2,
        name: 'Numéro de décodeur',
        code: 'carte',
        prefix: '',
      },
      {
        id: 1,
        name: 'Numéro de téléphone',
        code: 'phone',
        prefix: '00237',
      },
      {
        id: 3,
        name: 'Numéro d\'abonné',
        code: 'numabo',
        prefix: '',
      },
    ]

    watch(selectState, newValue => {
      console.log('route.value.query.qState')
      console.log(route.value.query.qState)
      if (route.value.query.qState !== undefined) {
        route.value.query.qState = newValue
      } else {
        console.log('props.id')
        console.log(props.id)
        if (!isNaN(props.id)) {
          loadDecodeurs(props.id)
        } else if (props.id === 'all') {
          loadAllDecodeurs()
        }
      }

      // console.log(route)
      // window.onpopstate = () => {
      //   history.pushState(route.value.query.qState, '', newValue)
      // }
    })

    watch(
      () => route.value.query.qState,
      () => {
        if (!isNaN(props.id)) {
          loadDecodeurs(props.id)
        } else if (props.id === 'all') {
          loadAllDecodeurs()
        }
      },
      {
        deep: true,
        immediate: true,
      },
    )

    watch(selectOffre, () => {
      if (!isNaN(props.id)) {
        loadDecodeurs(props.id)
      } else if (props.id === 'all') {
        loadAllDecodeurs()
      }
    })

    return {
      headers: [
        { text: 'Emplacement', value: 'location', align: 'center' },
        { text: 'N° Réabonnement/Décodeur', value: 'number', align: 'center' },
        { text: 'Offre', value: 'formula', align: 'center' },
        { text: 'Prélèvement automatique', value: 'is_auto', align: 'center' },
        { text: 'Début d\'abonnement', value: 'start_subscription', align: 'center' },
        { text: 'Fin d\'abonnement', value: 'end_subscription', align: 'center' },
        { text: 'Jours restants', value: 'remaining', align: 'center' },
        { text: 'Etat', value: 'state', align: 'center' },
        {
          text: 'Action', value: 'actions', sortable: false, divider: true,
        },
      ],
      local,
      decodeurList,
      decodeurListFormatted,
      status: {
        1: 'Actif',
        2: 'Echu',
      },
      isVisu,
      remainingDay,
      statusColor,
      search,
      chartOptions,
      isApiCanalAvailable,

      dialogPrel,
      openDialogPrel,
      acceptPrel,
      refusePrel,
      reaboAll,

      statusList,
      reaboAutoAll,
      updatePrel,

      truth,
      fail,

      dialog,
      Decodeur,
      formule,
      option,
      decodeurForm,
      LocalNameRules,
      LocalNumberRules,
      clearDateFin,
      loadFormules,
      loadOptions,
      nomFormule,
      nomOption,
      localItem,
      snackbar,
      loading,
      reaboAutomatique,
      createDecodeur,
      formatDecoderToSave,
      close,
      activeElement,
      isAuto,
      formatDate,
      persistDecoders,
      loadAllDecodeurs,
      decoderSelected,
      qState,
      filterDecodeurByState,
      qRoute,
      showAddDecoderBtn,
      programReabo,
      programUpgrade,
      isUpgradable,
      selectOffre,
      selectState,
      etat,
      submited,

      // changOffreFilter,
      // changStateFilter,

      xlFile,
      loadXlFile,
      imunition,
      codeFormule,
      codeOption,
      changed,
      userData,
      filesAccepted,

      dialogApi,
      searchForm,
      decoderApiSelected,
      decodeurs,
      recherche,
      prefix,
      typeRecherche,
      disabledCount: 0,
      dialogDelete: false,
      editedIndex: -1,

      headersDetails: [
        { text: 'N°d\'abonné', value: 'numabo', divider: true },
        { text: 'Décodeur', value: 'clabo', divider: true },
        { text: 'Offre', value: 'optionmajeureabo', divider: true },
        { text: 'Propriétaire', value: 'nomabo', divider: true },
        { text: 'Ville', value: 'villabo', divider: true },
        { text: 'Date de fin', value: 'finabo', divider: true },
        { text: 'Date d\'activation', value: 'date', divider: true },
      ],

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiReload,
        mdiArrowTopRight,
        mdiPlus,
        mdiCloseCircle,
        mdiPlusCircle,
        mdiCalendarMonth,
        mdiPaperclip,
        mdiDeleteOutline,
      },
    }
  },
  watch: {
    dialog(val) {
      // eslint-disable-next-line no-unused-expressions
      val || this.close()
    },
  },
  created() {
    moment.locale('fr')
    console.log(this.option)
    console.log(this.formule)
  },
  methods: {
    AddDecodeur() {
      this.dialog = true
    },
    addNewDecodeur() {
      this.Decodeur.push(
        {
          number: null,
          location: '',
          formula: '',
          option: '',
          start_subscription: '',
          end_subscription: '',
          menu: '',
          menu2: '',
          is_auto: 0,
          reabo_auto: false,
        },
      )
    },

    searchDecodeur(index) {
      const donnees = this.recherche

      console.log(this.recherche)
      const rechValue = this.recherche
      const rech = this.searchForm

      const v = []

      v.push(rech.validate())

      console.log('v', v)

      // if(donnees.type !== undefined && donnees.value !== undefined && donnees.value.trim() !== ''){
      if (!v.includes(false)) {
        // if(donnees.type.id===1 || donnees.type.id===2 || donnees.type.id===3){

        this.submited = true
        const param = { [rechValue.type.code]: rechValue.type.prefix + rechValue.value }

        Http.post('get-detail-contrat', param).then(reponse => {
          this.submited = false
          const { data } = reponse

          this.decodeurs = []
          this.decoderApiSelected = []

          if (data.length) {
            this.disabledCount = 0
            for (let i = 0; i < data.length; i++) {
              let disabled = false

              const number = data[i].subscriber.numdecodeur
              const val = this.Decodeur.filter(i => parseInt(i.number) === parseInt(number))

              if (val.length) {
                disabled = true
                this.disabledCount += 1
              }

              this.decodeurs.push({
                id: i + 1,
                clabo: data[i].subscriber.numdecodeur,
                nomabo: data[i].subscriber.nomabo,
                numabo: data[i].subscriber.numabo,
                optionmajeureabo: data[i].contract.offresLibelle,
                prenomabo: data[i].subscriber.prenomabo,
                villabo: data[i].subscriber.villecgaabo,
                finabo: data[i].contract.finabo,
                disabled,
              })
            }
            this.dialogApi = true
          }
        }).catch(error => {
          this.submited = false
          console.log(error)
        })
      } else {
        // this.snack.active = true
        // this.snack.color = "error"
        // this.snack.message = "Veuillez remplir le formulaire convenablement"
      }
    },

    validate() {
      this.closeApi()

      console.log(this.decoderSelected)

      // if (decodeurIndex === -1) {
      //   // eslint-disable-next-line no-param-reassign
      //   decodeurIndex = this.Decodeur.length - 1
      // }

      for (let i = 0; i < this.decoderApiSelected.length; i++) {
        this.Decodeur.push(
          {
            number: this.decoderApiSelected[i].clabo,
            location: '',
            formula: this.codeFormule(this.decoderApiSelected[i].optionmajeureabo),
            option: '',
            start_subscription: '',
            end_subscription: moment(this.decoderApiSelected[i].finabo, 'DD/MM/YYYY').format('YYYY-MM-DD'),
            menu: '',
            menu2: '',
            is_auto: 0,
            reabo_auto: false,
          },
        )
      }
    },

    closeApi() {
      this.dialogApi = false
      this.decodeurs = []
    },

    selectAllToggle(props) {
      if (this.decoderApiSelected.length !== (this.decodeurs.length - this.disabledCount)) {
        this.decoderApiSelected = []
        const self = this
        props.items.forEach(item => {
          if (!item.disabled) {
            self.decoderApiSelected.push(item)
          }
        })
      } else this.decoderApiSelected = []
    },

    changePrefix() {
      this.recherche.value = ''
      this.prefix = this.recherche.type.prefix
    },

    deleteItem(item) {
      console.log(item)
      this.editedIndex = this.decodeurListFormatted.indexOf(item)
      this.editedItem = { ...item }
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      const contrat = JSON.parse(localStorage.getItem('defaultContrat'))
      const mItem = this.editedItem
      this.snackbar.show = false
      console.log(mItem)
      console.log('editedItem : ', contrat.pivot.customer_segment_id)
      Http.get(`/delete-decoder/${contrat.pivot.customer_segment_id}/${this.editedItem.id}`)
        .then(response => {
          this.snackbar.message = response.message
          this.snackbar.show = true
          this.closeDelete()
          this.decodeurListFormatted.splice(this.editedIndex, 1)
          window.reload()
        })
        .catch(error => {
          console.log(error)
        })
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },
  },
}
</script>
<style lang="scss" scoped>

.blue {
  color: #2366ec;
}

fieldset {
  border-width: 1px !important;
  border-color: #ffffff;
}

.red {
  color: #ff4c51 !important;
  background-color: #ff4c51 !important;
}

.position-flex-center{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-items: center;
  align-items: center;
}
</style>
